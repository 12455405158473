<template lang="pug">
    div.main-container(:style="{backgroundSize:'cover', backgroundImage:'url(' + require('@/assets/img2/insigne-bg-stroke-grey.png') + ')'} ")
        div.overlay
       
        div.main-content(id="content")
            div.layout-px-spacing
                div.row.mt-3
                    div.col-lg-12.col-md-12.layout-spacing
                        div.widget.box.box-shadow
                            div.widget-header
                                div.row
                                    div.col-xl-12.col-md-12.col-sm-12.col-12.text-center
                                div.widget-content.widget-content-area
                                    div.col-lg-12.mx-auto
                                        iframe(id="targetFrame" :src="examData.find(o => o.bActual === true).sQuestionPath " width="1260px" height="640px")
                            <!--Inicia row de elementos nuevos-->
                            div.row.mx-auto.text-center
                                div.col-md-4
                                    div
                                        p
                                            svg(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round").feather.feather-clock
                                                circle(cx="12" cy="12" r="10")
                                                polyline(points="12 6 12 12 16 14")
                                            span(style="padding-left: 5px") {{formattedElapsedTime}}

                                div.col-md-4
                                    template {{examData.find(o => o.bActual === true).iOrder}} / {{ iTotalQuestions }}
                                
                                div.col-md-4
                                    div.btn-group.mt-n2
                                        button(@click="ShowQuestions()").btn.btn-light
                                            template {{ $t('btns.btnseequest') }}
                                        button(@click="CheckToAnswerLater()" v-if="examData.find(o => o.bActual === true).bLast == false").btn.btn-light
                                            template {{ $t('btns.btnreplyaft') }}
                                        button(@click="nextQuestion()" v-if="examData.find(o => o.bActual === true).bLast == false").btn.btn-light {{ $t('btns.btnnext') }} 
                                            svg(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round").feather.feather-chevron-right
                                                polyline(points="9 18 15 12 9 6")
                                        button(@click="FinishQuestionary()" v-if="examData.find(o => o.bActual === true).bLast == true").btn.btn-light {{ $t('btns.btnfinish') }} 
                                


</template>
<script>
/* eslint-disable */
export default {
    name: 'TakeExam2',
    props: ['idPersonExam', 'idGroupDetail'],
    components: {
    },    
    data() {
        return {
            examData: [],
            elapsedTime: 0,
            timer: undefined,
            iTotalQuestions: 0,
            iTotalTime: 0,
            iUsedTime: 0,
            idExamPool: 0,
            flag: false
        }
    },
    computed: {
        formattedElapsedTime() {
            
            const date = new Date(null);
            date.setSeconds(this.elapsedTime / 1000);
            const utc = date.toUTCString();
            if(this.elapsedTime >= this.iTotalTime){
                clearInterval(this.timer);
                new Promise(r => setTimeout(r, 2000));
                this.FinishQuestionary()
                return
            }
            
            return utc.substr(utc.indexOf(":") - 2, 8);
            
        }
    },    
    methods: {
        async getData(){
            await axios.get(apiURL + 'Student/getExamData?idPersonExam='+ this.idPersonExam + "&idGroupDetail=" + this.idGroupDetail, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                if(response.data.sErrors.length > 0){
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.sErrors[0],
                        footer: ''
                    })
                    return                    
                }
                this.examData = response.data.examData
                this.elapsedTime = response.data.iUsedTime * 1000 
                this.start()
                this.iTotalQuestions = response.data.iTotalQuestions
                this.iTotalTime = response.data.iTotalTime * 1000
                this.iUsedTime = response.data.iUsedTime
                this.idExamPool = response.data.idExamPool
                console.log(response.data.iUsedTime * 1000)
            } )
            .catch( (error)  => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                    footer: ''
                })
                return                    
            } )

        },
        async ShowQuestions(){
            router.push('/student/QuestionsList/'+this.idExamPool+'/'+this.examData.find(o => o.bActual === true).idResult)
        },
        async nextQuestion(){
            var ventana = document.getElementById('targetFrame').contentWindow
            var iScore = ventana.AtomiAP.presentations[ventana.AtomiAP.presentations.length-1].variable("apQuizScore");
            
            await axios.post(apiURL + 'Student/saveQuestionResult?idResultQuestion=' + this.examData.find(o => o.bActual === true).idResultQuestion + '&iScore=' + iScore + '&iUsedTime=' + this.elapsedTime/1000 , null, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                if(response.data.sErrors.length > 0){
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.sErrors[0],
                        footer: ''
                    })
                    return                    
                }                
                var newIOrder = this.examData.find(o => o.bActual === true).iOrder + 1;
                this.examData.find(o => o.bActual === true).bActual = false;
                this.examData.find(o => o.iOrder === newIOrder).bActual = true;

            } )
            .catch( (error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                    footer: ''
                })
                return                    
            } )                        
        },

        async CheckToAnswerLater(){
            await axios.get(apiURL + 'Student/checkToAnswerLater?idResultQuestion='+ this.examData.find(o => o.bActual === true).idResultQuestion + "&check=true", { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                if(this.examData.find(o => o.bActual === true).bLast == true){
                    this.FinishQuestionary()
                }
                else{
                    this.nextQuestion()
                }
            } )
            .catch( (error)  => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                    footer: ''
                })
                return                    
            } )
        },

        checkMarkedQuestions() {
            axios.get(apiURL + 'Student/checkMarkedQuestions?idResult='+ this.examData.find(o => o.bActual === true).idResult, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {


            } )
            .catch( (error)  => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                    footer: ''
                })
            } )
            return
        },

        async FinishQuestionary(){
            var ventana = document.getElementById('targetFrame').contentWindow
            var iScore = ventana.AtomiAP.presentations[ventana.AtomiAP.presentations.length-1].variable("apQuizScore");

            axios.get(apiURL + 'Student/checkMarkedQuestions?idResult='+ this.examData.find(o => o.bActual === true).idResult, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                if(response.data.bIsCorrect) {
                    Swal.fire({
                    title: this.$t('takeExam2.questionMessage'),
                    html: this.$t('takeExam2.confirmMessage'),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: this.$t('takeExam2.yesMessage'),
                    cancelButtonText: this.$t('takeExam2.noMessage')
                    }).then((result) => {
                        if (result.isConfirmed) {
                            axios.post(apiURL + 'Student/saveSingleQuestion?idResultQuestion=' + this.examData.find(o => o.bActual === true).idResultQuestion + '&iScore=' + iScore + '&iUsedTime=' + this.elapsedTime/1000 , null, { headers: {
                                'Content-Type': 'application/json',
                                Authorization: 'Bearer ' + localStorage.userToken
                            } })
                            .then( (response) => {
                                if(response.data.sErrors.length > 0){
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'Error',
                                        text: response.data.sErrors[0],
                                        footer: ''
                                    })
                                }                
                                router.push('/student/QuestionsList/'+ this.idExamPool + '/' + this.examData.find(o => o.bActual === true).idResult)
                            } )
                            .catch( (error) => {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Error',
                                    text: error,
                                    footer: ''
                                })
                                return                    
                            } )                        
                            return
                        }
                        else{
                            axios.post(apiURL + 'Student/finishQuestionary?idResultQuestion=' + this.examData.find(o => o.bActual === true).idResultQuestion + '&iScore=' + iScore + '&idResult=' + this.examData.find(o => o.bActual === true).idResult + '&iTotalTime=' + this.elapsedTime/1000, null, { headers: {
                                'Content-Type': 'application/json',
                                Authorization: 'Bearer ' + localStorage.userToken
                            } })
                            .then( (response) => {
                                if(response.data.sErrors.length > 0){
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'Error',
                                        text: response.data.sErrors[0],
                                        footer: ''
                                    })
                                    return                    
                                }                
                                console.log('Examen terminado')
                                router.push('/student/Results/' + this.idPersonExam + '/' + this.idGroupDetail)
                            } )
                            .catch( (error) => {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Error',
                                    text: error,
                                    footer: ''
                                })
                                return                    
                            } )            
                        }
                    })
                }
                else
                {
                    axios.post(apiURL + 'Student/finishQuestionary?idResultQuestion=' + this.examData.find(o => o.bActual === true).idResultQuestion + '&iScore=' + iScore + '&idResult=' + this.examData.find(o => o.bActual === true).idResult + '&iTotalTime=' + this.elapsedTime/1000, null, { headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + localStorage.userToken
                    } })
                    .then( (response) => {
                        if(response.data.sErrors.length > 0){
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: response.data.sErrors[0],
                                footer: ''
                            })
                            return                    
                        }                
                        console.log('Examen terminado')
                        router.push('/student/Results/' + this.idPersonExam + '/' + this.idGroupDetail)
                    } )
                    .catch( (error) => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: error,
                            footer: ''
                        })
                        return                    
                    } )                                
                }
            } )
            .catch( (error)  => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                    footer: ''
                })
            } )
            
        },
        start() {
            this.timer = setInterval(() => {
                this.elapsedTime += 1000;
            }, 1000);
        },
    },
    mounted() {
       this.checkPermisions(this.$router.currentRoute.path)
       this.getData()
       
    }
}
</script>